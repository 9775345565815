import { useState } from 'react'

import { IdsMainButton, IdsIcon, IdsLoading } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import IconSetaDireita from '@mobi/ds/static/icons/outlined/seta-direita.svg'
import IconWhatsapp from '@mobi/ds/static/icons/outlined/whatsapp.svg'
import classNames from 'classnames'
import { isClient } from 'utils'

import './navigation.scss'
import services from './services'

const Navigation = ({
  nextStep,
  nextStepIcon = 'arrow_right_base',
  nextButtonText = 'Continuar simulação',
  nextButtonId = '',
  isNextButtonDisabled = false,
  isNextStepButtonLoading = false,
  onNextStepClick,
  onWppClick,
  ghost,
  nextButtonType,
  hasWppBtn = false
}) => {
  const className = classNames('navigation', {
    '-ghost': ghost
  })

  const proposalContext = useProposal()
  const setIsLoading = proposalContext.setIsLoading

  const [wppClicked, setWppClicked] = useState(false)

  const wppClassName = classNames('navigation__wpp', {
    '--fullWidth': wppClicked
  })

  const handleWpp = async () => {
    if (!wppClicked) {
      setWppClicked(true)
      return
    }

    if (onWppClick) onWppClick()

    try {
      setIsLoading(true)
      const { link } = await services.finishSimulationOnWpp()

      isClient() && window.location.assign(link)
    } catch {
      setIsLoading(false)
    }
  }

  return (
    <div className={className}>
      <div>
        <If
          condition={hasWppBtn}
          renderIf={
            <button type="button" className={wppClassName} onClick={handleWpp}>
              <div className="navigation__wpp-content">
                <IconWhatsapp className="navigation__wpp-icon" />
                <p className="navigation__wpp-text">
                  Dúvidas? Finalize pelo WhatsApp
                </p>
                <IconSetaDireita className="navigation__arrow-icon" />
              </div>
            </button>
          }
        />
      </div>

      <div>
        <If
          condition={nextStep}
          renderIf={
            <IdsMainButton
              id={nextButtonId}
              type={nextButtonType}
              onClick={onNextStepClick}
              disabled={isNextButtonDisabled || isNextStepButtonLoading}
              full={true}
              className="next-step"
              loading={isNextStepButtonLoading}
            >
              <If
                condition={isNextStepButtonLoading}
                renderElse={
                  <>
                    {nextButtonText}
                    <If
                      condition={nextStepIcon}
                      renderIf={<IdsIcon>{nextStepIcon}</IdsIcon>}
                    />
                  </>
                }
                renderIf={
                  <IdsLoading
                    className="wrapper-sendeer__submit-loading"
                    aria-live="polite"
                    aria-label="aguarde, estamos enviando seus dados..."
                    size="XS"
                    show={true}
                  />
                }
              />
            </IdsMainButton>
          }
        />
      </div>
    </div>
  )
}

export default Navigation
