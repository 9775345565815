import { IdsModal } from '@ids/react'
import { useLead } from '@itau-loans-www/shopping/src/hooks'

import BulletPointsComponent from './bulletPointsComponent'

const ConsorcioModal = () => {
  const { setOfferModalOpen, offerModalOpen } = useLead()
  return (
    <IdsModal
      open={offerModalOpen}
      setOpen={setOfferModalOpen}
      title="Consórcio Itaú"
      width="1000px"
    >
      <div className="hero-consorcio__modal-wrapper">
        <p className="hero-consorcio__modal-description">
          O consórcio é um produto financeiro tradicional no Brasil, ideal para
          quem quer comprar um bem de valor mais alto, como carro, casa, moto ou
          caminhão, sem pagar juros e sem entrada.
        </p>
        <p className="hero-consorcio__modal-description">
          Ao participar, você paga parcelas mensais e participa de sorteios que
          definem quem receberá a carta de crédito para comprar o bem. Também é
          possível dar lances para aumentar as chances de ser contemplado. Até o
          fim do período, todos os membros do grupo serão contemplados.
        </p>
        <p className="hero-consorcio__modal-description">
          Se você deseja se planejar para essa compra, o Consórcio Itaú é pra
          você.
        </p>

        <span className="hero-consorcio__modal-title">
          Vantagens do Consórcio
        </span>
        <BulletPointsComponent />
      </div>
    </IdsModal>
  )
}

export default ConsorcioModal
