import { useState } from 'react'

import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks'
import { ButtonContinue } from '@itau-loans-www/shopping/src/components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './second-offer-cgi.scss'

const Cgi = (props) => {
  const { currentProposal } = props

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    buttonTrackingName,
    secondOfferHighlights
  } = currentProposal

  return (
    <div className="second-offer-card__wrapper">
      <div className="second-offer-card__header">
        <p className="second-offer-card__title --fixed-height">{title}</p>
      </div>

      <div className="second-offer-card__main-content">
        <EditForm
          proposal={currentProposal}
          setCardButtonDisabled={setIsButtonDisabled}
        />

        <AdditionalData list={secondOfferHighlights} />
      </div>

      <div className="second-offer-card__final-content">
        <ButtonContinue
          {...props}
          buttonText={buttonText}
          ariaLabel={buttonAriaLabel}
          callback={finishProposalCallback}
          dataTestid={'secondary-offer-cgi-btn'}
          id={`second-offer-${productCode}-btn`}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
          disabled={isButtonDisabled}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default Cgi
