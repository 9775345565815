import analytics from '@mobi/libraries/analytics'
import { goTo, segment } from 'utils'

import services from './services'

export const trackOnContinue = async (productCode) => {
  segment.buttonClick({
    currentButton: {
      innerText: 'Desbloquear',
      dataset: { id: `locked-offer-${productCode}-btn` }
    },
    payload: {
      productName: productCode,
      type: 'Desbloqueio'
    }
  })

  analytics.track('click', {
    currentPage: 'vitrine-shopping',
    detail: 'click-oferta-bloqueada',
    customLayer: {
      description: productCode
    }
  })
  const { next_step } = await services.getNextStepBlocked(productCode)

  goTo(next_step)
}
