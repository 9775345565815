import { If, Input } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import {
  SkeletonLoaderInput,
  SkeletonLoaderInfo
} from '../../../../../../components/SkeletonLoader'
import './cgi-second-edit-form.scss'
import { useEditForm } from './hooks'

const EditForm = ({ proposal, setCardButtonDisabled }) => {
  const { offers } = useLead()
  const currentProposal = offers?.hero ?? proposal

  const {
    maxLoanValue,
    minLoanValue,
    initialInstallment,
    interestRateMonthly,
    lastInstallment
  } = currentProposal

  const {
    // onSubmit,
    isFormSending,
    isEditing,
    requestedDefaultValue,
    control,
    // onEditClick,
    // isValid,
    isToRerender
  } = useEditForm({
    proposal,
    setCardButtonDisabled
  })

  return (
    <div className="cgi-second-edit-form">
      <div className="cgi-second-edit-form__value-wrapper">
        <div className="cgi-second-edit-form__field">
          <If
            condition={isFormSending}
            renderIf={<SkeletonLoaderInput />}
            renderElse={
              <>
                <Input.Currency
                  id="value"
                  label="valor sugerido do empréstimo"
                  name="value"
                  type="currency"
                  shouldResetOn={isToRerender}
                  disabled={isFormSending || !isEditing}
                  defaultValue={requestedDefaultValue}
                  rules={{
                    min: minLoanValue,
                    max: maxLoanValue
                  }}
                  control={control}
                />

                <p className="cgi-second-edit-form__disclaimer">
                  De {formatters.number.toCurrency(minLoanValue, 0)} até{' '}
                  {formatters.number.toCurrency(maxLoanValue, 0)}
                </p>
              </>
            }
          />
        </div>
        {/* <If
          condition={!isEditing}
          renderIf={
            <IdsContextualButton
              id="edit-btn"
              data-testid="edit-value-btn"
              onClick={onEditClick}
              disabled={isFormSending}
            >
              Editar
            </IdsContextualButton>
          }
          renderElse={
            <IdsMainButton
              id="edit-submit-btn"
              data-testid="ok-edit-value-btn"
              onClick={onSubmit}
              isLoading={isFormSending}
              disabled={isFormSending || !isValid}
            >
              Ok
            </IdsMainButton>
          }
        /> */}
      </div>
      <If
        condition={isFormSending}
        renderIf={<SkeletonLoaderInfo />}
        renderElse={
          <>
            <div className="cgi-second-edit-form__info">
              <p className="cgi-second-edit-form__info-title">
                primeira parcela
              </p>
              <div className="cgi-second-edit-form__info-values">
                <strong className="cgi-second-edit-form__info-value">
                  R$ {formatters.number.toFloat(initialInstallment)}
                </strong>
                Taxa de {formatters.number.toPercent(interestRateMonthly, 2)}{' '}
                a.m.
              </div>
            </div>
            <div className="cgi-second-edit-form__info">
              <p className="cgi-second-edit-form__info-title">ultima parcela</p>
              <strong className="cgi-second-edit-form__info-value">
                R$ {formatters.number.toFloat(lastInstallment)}
              </strong>
            </div>
          </>
        }
      />
    </div>
  )
}

export default EditForm
