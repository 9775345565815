import { IdsProgressBar } from '@ids/react'
import classnames from 'classnames'

import './stepper.scss'

const Stepper = ({ className, title, index, max }) => {
  const stepperClass = classnames('stepper', className, {})
  const percentage = (index / max) * 100

  return (
    <div className={stepperClass}>
      <IdsProgressBar fill={percentage} />
      <div
        className="stepper__content"
        role="text"
        aria-label={`${title}, passo ${index} de ${max}`}
      >
        <p className="stepper__state" aria-hidden="true">
          Passo {index} de {max}
        </p>
      </div>
    </div>
  )
}

export default Stepper
