import { useState } from 'react'

import { ButtonContinue } from '@itau-loans-www/shopping/src/components'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import EditForm from './components/EditForm'
import './hero-cgi.scss'

const CgiCondition = (props) => {
  const { currentProposal } = props
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const {
    title,
    buttonText,
    productCode,
    buttonAriaLabel,
    heroOfferHighlights,
    buttonTrackingName
  } = currentProposal

  return (
    <div className="cgi-hero">
      <div className="hero-offer__header">
        <p className="hero-offer__title">{title}</p>
      </div>

      <div className="hero-offer__content">
        <EditForm
          proposal={currentProposal}
          setCardButtonDisabled={setIsButtonDisabled}
        />

        <AdditionalData list={heroOfferHighlights} />

        <ButtonContinue
          {...props}
          buttonTracking={buttonTrackingName}
          callback={finishProposalCallback}
          buttonText={buttonText}
          ariaLabel={buttonAriaLabel}
          id={`hero-offer-${productCode}-btn`}
          dataTestid="hero-offer-cgi-btn"
          disabled={isButtonDisabled}
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default CgiCondition
