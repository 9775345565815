import { DsCookieBar } from '@itau-loans-www/shopping/src/components'

import './cookie-bar.scss'

const CookieBar = () => (
  <DsCookieBar
    finalText=" e, ao continuar navegando neste site, você declara estar ciente dessas condições."
    initialText="Este site usa cookies e dados pessoais de acordo com os nossos "
    link="https://www.itau.com.br/seguranca/termos-de-uso/"
    textLink="Termos de Uso e Política de Privacidade"
  />
)

export default CookieBar
